@import "../../../variables";

.picker-content {
  margin: 4rem 0;
  .picker {
    margin-top: 2rem;
    button {
      border-radius: 1rem;
      padding: 1rem 1rem;
      white-space: nowrap;
      outline: none;
      width: 30%;
      border: none;
      &.active-button {
        background: $button-background;
        color: $button-color;
      }
    }
    .carousel-slider {
      padding-bottom: 2.5rem;
    }

    .carousel-status,
    .control-arrow{
      display: none;
    }
  }
}
.exclusion-content {
  .icon {
    width: 2rem;
    height: 3rem;
    float: left;
    display: grid;
    align-content: center;
  }
}